import { Grid, Typography } from '@mui/material'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import { useEffect, useState } from 'react'
import { getAllDataFromPath, updateOrCreateDocument } from '../../firebase'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import { useSnackbar } from 'notistack'

const EditEventPage = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const pathname = window.location.pathname
  const id = pathname.split('/')[2]
  const [data, setData] = useState(null)
  const [formData, setFormData] = useState({ elements: [] })
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    },
    title: {
      fontSize: '34px',
      fontWeight: '900',
      textTransform: 'uppercase',
      marginBottom: '10px',
    },
    description: {
      fontSize: '20px',
      fontWeight: '300',
      textAlign: 'justify',
    },
    contacts: {
      fontSize: '28px',
      fontWeight: '300',
      marginBottom: '20px',
      textAlign: 'justify',
    },
    img: {
      width: '100%',
      marginBottom: '20px',
    },
    boxData: {
      padding: '20px',
      boxShadow: 'rgba(0, 0, 0, 0.15) 2px 2px 5px 2px',
    }
  }
  const editEvent = (e) => {
    const tempData = data?.data
    tempData.title = e.title
    tempData.description = e.description
    tempData.descriptionSmall = e.descriptionSmall
    // if (e?.files?.length > 0) {
    //   e?.files?.forEach((image) => {
    //     tempData.files.push(image)
    //   })
    // }
    updateOrCreateDocument('events', id, tempData).then(() => {
      enqueueSnackbar('Edycja przebiegła pomyślnie.', { variant: 'success' })
      setTimeout(() => props.location.history.push('/eventsAdmin'), 1000)
    })
  }
  useEffect(() => {
    if (id) {
      getAllDataFromPath('events').then((res) => {
        if (res) {
          res?.forEach((el) => {
            if (el?.id === id) {
              setData(el)
            }
          })
        }
      })
    }
  }, [id])
  useEffect(() => {
    const element = data?.data
    if (element?.title && element?.description && element?.descriptionSmall) {
      const dataTemp = {
        elements: [
          {
            name: 'title',
            label: 'Tytuł wydarzenia',
            value: element?.title,
            type: 'text',
            validationType: 'string',
          },
          {
            name: 'descriptionSmall',
            label: 'Krótki opis',
            value: element?.descriptionSmall,
            type: 'text',
            validationType: 'string',
          },
          {
            name: 'description',
            label: 'Długi opis',
            value: element?.description,
            type: 'editor',
            validationType: 'string',
          },
          // {
          //   type: 'files',
          //   name: 'files',
          //   label: 'Zdjęcia z wydarzenia',
          // },
          {
            type: 'button',
            value: 'Zapisz'
          }
        ]
      }
      setFormData(dataTemp)
    }
  }, [data?.data])
  return (
    <div style={style.root}>
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <Typography sx={style.title}>Edycja aktualności</Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          {
            formData?.elements?.length > 0 && (
              <FormGenerator data={formData} submit={(e) => editEvent(e)} />
            )
          }
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid container spacing={4}>
            {
              data?.data?.files?.map((el, index) => {
                return (
                  <Grid item md={6} xs={12} key={index}>
                    <img src={el} alt={index} style={{ width: '100%' }} />
                  </Grid>
                )
              })
            }
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default EditEventPage
