import { getAllDataFromPath } from '../../firebase'
import { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Dialog from '../../components/atoms/Dialog/Dialog'

const EventPage = () => {
    const id = window.location.pathname.split('/')[2]
    const [open, setOpen] = useState(false)
    const [data, setData] = useState(null)
    const [previewImage, setPreviewImage] = useState(null)
    const [element, setElement] = useState(null)
    const style = {
        title: {
            fontSize: '34px',
            fontWeight: '900',
            textTransform: 'uppercase',
            marginBottom: '10px'
        },
        description: {
            fontSize: '20px',
            fontWeight: '300',
            marginBottom: '20px',
            textAlign: 'justify'
        },
        contacts: {
            fontSize: '28px',
            fontWeight: '300',
            marginBottom: '20px',
            textAlign: 'justify'
        },
        img: {
            width: '100%',
            marginBottom: '50px',
            cursor: 'pointer',
        }
    }
    const getAllEvents = () => {
        getAllDataFromPath('events').then((res) => {
            if (res) {
                setData(res)
            }
        })
    }
    const handlePreview = (img) => {
        setPreviewImage(img)
        setOpen(true)
    }
    useEffect(() => {
        getAllEvents()
    }, [])
    useEffect(() => {
        if (data) {
            data?.forEach((el) => {
                if (el?.id === id) {
                    setElement(el)
                }
            })
        }
    }, [data])
    return (
        <Grid container spacing={4}>
            <Grid item md={12} xs={12}>
                <Typography variant="h4" sx={style.title}><b>{element?.data?.title}</b></Typography>
                <Box dangerouslySetInnerHTML={{ __html: element?.data?.description }}/>
            </Grid>
            {
                element?.data?.files?.map((el, index) => {
                    return (
                        <Grid item md={3} xs={12} key={index}>
                            <img src={el} alt="image6" onClick={() => handlePreview(el)} style={style.img}/>
                        </Grid>
                    )
                })
            }
            {
                open && (
                    <Dialog
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        title="Podgląd zdjęcia"
                        open={open}
                        content={(
                            <img
                                src={previewImage}
                                alt="podglad zdjecia"
                                style={{
                                    width: '100%',
                                    maxWidth: '1024px',
                                }}
                            />
                        )}
                        onClose={() => setOpen(false)}
                    />
                )
            }
        </Grid>
    )
}

export default EventPage
