import { Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import image3 from '../../assets/images/3.jpg'

const HistoryPage = () => {
  const style = {
    text: {
      marginBottom: '20px',
      textAlign: 'justify',
    },
    textHeading: {
      fontSize: '34px',
      fontWeight: '900',
      textTransform: 'uppercase',
      marginBottom: '10px',
    },
    textHeadingCenter: {
      fontSize: '34px',
      fontWeight: '900',
      textTransform: 'uppercase',
      marginBottom: '50px',
      textAlign: 'center',
    },
    textCenter: {
      textAlign: 'center',
      marginBottom: '10px',
    },
    box: {
      padding: '0px 0',
    },
    img: {
      width: '100%',
    },
  }
  return (
    <div>
      <Box sx={style.box}>
        <Grid container spacing={4}>
          <Grid item md={6}>
            <img src={image3} alt="image3" style={style.img} />
          </Grid>
          <Grid item md={6}>
            <Typography variant="h4" sx={style.textHeading}><b>Historia straży pożarnej</b></Typography>
            <Typography sx={style.text}>
              Historia OSP Mścice sięga roku 1948, kiedy to powstała ochotnicza straż pożarna w tej miejscowości. Była to grupa kilkunastu ochotników, którzy z własnej inicjatywy podjęli się ochrony mieszkańców przed zagrożeniami pożarowymi.
            </Typography>
            <Typography sx={style.text}>
              Początkowo OSP Mścice korzystała z bardzo skromnego sprzętu, który udało się pozyskać dzięki wsparciu lokalnej społeczności. W kolejnych latach udało się jednak zwiększyć liczbę członków drużyny oraz zmodernizować wyposażenie, co pozwoliło na skuteczniejsze działanie w przypadku pożarów.
            </Typography>
            <Typography sx={style.text}>
              W kolejnych dziesięcioleciach OSP Mścice brała udział w licznych akcjach ratowniczych i gaśniczych, nie tylko na terenie swojej miejscowości, ale również poza nią. Członkowie drużyny regularnie podnosili swoje kwalifikacje, uczestnicząc w szkoleniach i kursach z zakresu ratownictwa i ochrony przeciwpożarowej.
            </Typography>
            <Typography sx={style.text}>
              Obecnie OSP Mścice jest jednym z najważniejszych punktów obrony przeciwpożarowej na terenie swojego regionu. Drużyna liczy kilkudziesięciu członków, którzy dysponują nowoczesnym sprzętem i stale doskonalą swoje umiejętności, by jak najlepiej sprostać wszelkim wyzwaniom związanym z ochroną ludzi i mienia przed zagrożeniami pożarowymi.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default HistoryPage
