import { Box, Button, Grid, Typography } from '@mui/material'
import im18 from '../../assets/images/child/1.png'
import { useHistory } from 'react-router'
import { useEffect, useState } from 'react'
import { getAllDataFromPath } from '../../firebase'

const EventsPage = () => {
  const history = useHistory()
  const [data, setData] = useState(null)
  const style = {
    title: {
      fontSize: '34px',
      fontWeight: '900',
      textTransform: 'uppercase',
      marginBottom: '10px'
    },
    description: {
      fontSize: '20px',
      fontWeight: '300',
      marginBottom: '20px',
      textAlign: 'justify'
    },
    contacts: {
      fontSize: '28px',
      fontWeight: '300',
      marginBottom: '20px',
      textAlign: 'justify'
    },
    img: {
      width: '100%',
      marginBottom: '50px'
    }
  }
  const getAllEvents = () => {
    getAllDataFromPath('events').then((res) => {
      if (res) {
        res.sort((a, b) => new Date(b.data.date) - new Date(a.data.date))
        setData(res)
      }
    })
  }
  useEffect(() => {
    getAllEvents()
  }, [])
  return (
    <div>
      <Box sx={style.box}>
        <Grid container spacing={4}>
          {
            data?.map((el, index) => {
              const elements = el.data
              return (
                <Grid key={index} item md={12} xs={12}>
                  <Box sx={{ marginBottom: '20px', borderBottom: index !== data?.length - 1 ? '1px solid rgba(50, 75, 84, 0.3)' : 'none', paddingBottom: '50px' }}>
                    <Grid container spacing={4}>
                      <Grid item md={3} xs={12}>
                        <img src={elements.files[0]} alt={elements.title} style={{ width: '100%' }}/>
                      </Grid>
                      <Grid item md={9} xs={12}>
                        <Typography variant="h4" sx={style.title}>{elements.title}</Typography>
                        <Typography variant="h5">{elements.descriptionSmall}</Typography>
                        <Button variant="contained" onClick={() => history.push(`/event/${el.id}`)}
                                sx={{ marginTop: '10px' }}>
                          Czytaj więcej
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )
            })
          }
        </Grid>
      </Box>
    </div>
  )
}

export default EventsPage
