import { Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import image6 from '../../assets/images/6.jpg'

const TeamPage = () => {
  const style = {
    text: {
      marginBottom: '20px',
      textAlign: 'justify',
    },
    textHeading: {
      fontSize: '34px',
      fontWeight: '900',
      textTransform: 'uppercase',
      marginBottom: '10px',
    },
    textHeadingCenter: {
      fontSize: '34px',
      fontWeight: '900',
      textTransform: 'uppercase',
      marginBottom: '50px',
      textAlign: 'center',
    },
    textCenter: {
      textAlign: 'center',
      marginBottom: '10px',
    },
    box: {
      padding: '50px 0',
    },
    img: {
      width: '100%',
    },
  }
  const teams = [
    {
      name: 'dh Krzysztof Szczur',
      position: 'Prezes Zarządu',
      image: image6,
    },
    {
      name: 'dh Marek Pacek',
      position: 'Wiceprezes Zarządu - Naczelnik',
      image: image6,
    },
    {
      name: 'dh Bartłomiej Wilk',
      position: 'Wiceprezes Zarządu - Skarbnik',
      image: image6,
    },
    {
      name: 'dh Andrzej Chomątek',
      position: 'Członek Zarządu',
      image: image6,
    },
    {
      name: 'dh Krzysztof Frankowski',
      position: 'Członek Zarządu',
      image: image6,
    },
    {
      name: 'dh Marcin Frankowski',
      position: 'Członek Zarządu',
      image: image6,
    },
    {
      name: 'dh Łukasz Lipiński',
      position: 'Członek Zarządu',
      image: image6,
    },
    {
      name: 'dh Maciej Karwecki',
      position: 'Sekretarz Zarządu - Koordynator MDP',
      image: image6,
    },
    {
      name: 'dh Bogdan Kasprzyk',
      position: 'Przewodniczący Komisji Rewizyjnej',
      image: image6,
    },
    {
      name: 'dh Karol Frankowski',
      position: 'Członek Komisji Rewizyjnej',
      image: image6,
    },
    {
      name: 'dh Mariusz Augustyniak',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Michał Augustyniak',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Joanna Drętkiewicz',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Grzegorz Frankowski',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Mariusz Gosławski',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Krystian Kelm',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Wiktor Kulesza',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Zbigniew Kowalczyk',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Konrad Kujawski',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Leszek Lenarcik',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Artur Litwin',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Konrad Łęga',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Bartosz Maciejewski',
      position: 'Strażak / Z-ca Koordynatora MDP',
      image: image6,
    },
    {
      name: 'dh Sebastian Myrga',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Mariusz Michalak',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Remigiusz Mitryniuk',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Kamil Obidziński',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Piotr Przydalski',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Jakub Przydalski',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Jan Sachs',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Dawid Szpuntowicz',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Angelika Solecka',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Paweł Solecki',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Patryk Szperlak',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Radosław Todorowski',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Przemysław Teska',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Dorota Teska',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Daniel Terelak',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Paweł Węglewski',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Adam Wojciechowski',
      position: 'Strażak',
      image: image6,
    },
    {
      name: 'dh Kamil Wilczyński',
      position: 'Strażak',
      image: image6,
    },
  ]
  const teamsPro = [
    {
      name: 'dh Maria Pacek',
      position: 'Członek Honorowy',
      image: image6,
    },
    {
      name: 'dh Ryszard Pacek',
      position: 'Członek Honorowy',
      image: image6,
    },
    {
      name: 'dh Krystyna Szczur',
      position: 'Członek Honorowy',
      image: image6,
    },
  ]
  const teamsSmall = [
    {
      name: 'dh Eryk Todorowski',
      position: 'Członek MDP / Dowódca Drużyny',
      image: image6,
    },
    {
      name: 'dh Paweł Szczur',
      position: 'Członek MDP / Z-ca Dowódcy Drużyny',
      image: image6,
    },
    {
      name: 'dh Radek Augustyniak',
      position: 'Członek MDP',
      image: image6,
    },
    {
      name: 'dh Filip Augustyniak',
      position: 'Członek MDP',
      image: image6,
    },
    {
      name: 'dh Szymon Bordun',
      position: 'Członek MDP',
      image: image6,
    },
    {
      name: 'dh Gracjan Dereń',
      position: 'Członek MDP',
      image: image6,
    },
    {
      name: 'dh Marcel Dobrowolski',
      position: 'Członek MDP',
      image: image6,
    },
    {
      name: 'dh Konrnel Drętkiewicz',
      position: 'Członek MDP',
      image: image6,
    },
    {
      name: 'dh Kacper Górnaś',
      position: 'Członek MDP',
      image: image6,
    },
    {
      name: 'dh Filip Gródecki',
      position: 'Członek MDP',
      image: image6,
    },
    {
      name: 'dh Alan Kowalczyk',
      position: 'Członek MDP',
      image: image6,
    },
    {
      name: 'dh Kacper Kusyk',
      position: 'Członek MDP',
      image: image6,
    },
    {
      name: 'dh Antoni Maciejewski',
      position: 'Członek MDP',
      image: image6,
    },
    {
      name: 'dh Marcel Mielczarek',
      position: 'Członek MDP',
      image: image6,
    },
    {
      name: 'dh Kacper Mielnik',
      position: 'Członek MDP',
      image: image6,
    },
    {
      name: 'dh Mateusz Pacek',
      position: 'Członek MDP',
      image: image6,
    },
    {
      name: 'dh Mikołaj Sobieraj',
      position: 'Członek MDP',
      image: image6,
    },
    {
      name: 'dh Maja Szczur',
      position: 'Członek MDP',
      image: image6,
    },
    {
      name: 'dh Robert Kowalski - Wydra',
      position: 'Członek MDP',
      image: image6,
    },
  ]
  return (
    <div>
      <Box sx={style.box}>
        <Grid container spacing={4}>
          <Grid item md={12}>
            <Typography variant="h4" sx={style.textHeadingCenter}><b>Członkowie</b></Typography>
          </Grid>
          {
            teams.map((el, index) => (
              <Grid key={index} item md={3} xs={12}>
                <img src={el.image} alt="image3" style={style.img} />
                <Typography sx={{ textAlign: 'center', fontSize: '20px', fontWeight: '600', marginTop: '20px' }}>{el.name}</Typography>
                {
                  el.position?.length > 0 && (
                    <Typography sx={style.textCenter}>{el.position}</Typography>
                  )
                }
              </Grid>
            ))
          }
        </Grid>
      </Box>
      <Box sx={style.box}>
        <Grid container spacing={4}>
          <Grid item md={12}>
            <Typography variant="h4" sx={style.textHeadingCenter}><b>Członkowie honorowi</b></Typography>
          </Grid>
          {
            teamsPro.map((el, index) => (
              <Grid key={index} item md={3} xs={12}>
                <img src={el.image} alt="image3" style={style.img} />
                <Typography sx={{ textAlign: 'center', fontSize: '20px', fontWeight: '600', marginTop: '20px' }}>{el.name}</Typography>
                {
                  el.position?.length > 0 && (
                    <Typography sx={style.textCenter}>{el.position}</Typography>
                  )
                }
              </Grid>
            ))
          }
        </Grid>
      </Box>
      <Box sx={style.box}>
        <Grid container spacing={4}>
          <Grid item md={12}>
            <Typography variant="h4" sx={style.textHeadingCenter}><b>Członkowie Młodzieżowej Drużyny Pożarniczej</b></Typography>
          </Grid>
          {
            teamsSmall.map((el, index) => (
                <Grid key={index} item md={3} xs={12}>
                  <img src={el.image} alt="image3" style={style.img} />
                  <Typography sx={{ textAlign: 'center', fontSize: '20px', fontWeight: '600', marginTop: '20px' }}>{el.name}</Typography>
                  {
                      el.position?.length > 0 && (
                          <Typography sx={style.textCenter}>{el.position}</Typography>
                      )
                  }
                </Grid>
            ))
          }
        </Grid>
      </Box>
    </div>
  )
}

export default TeamPage
