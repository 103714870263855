import React from 'react'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import { Grid } from '@mui/material'
import bannerImage from '../../../assets/images/slider2.jpg'

const SmallSlider = (props) => {
	const style = {
		root: {
			background: 'rgb(247, 249, 252)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: BreakpointsHelper.isMobile() ? 'column' : 'row',
			position: 'relative',
			top: 0,
			padding: BreakpointsHelper.isMobile() ? '15px' : '50px',
			marginBottom: '50px',
		},
		bigTitle: {
			fontSize: BreakpointsHelper.isMobile() ? '24px' : '50px',
			lineHeight: BreakpointsHelper.isMobile() ? '24px' : '50px',
			fontWeight: '900',
			color: '#324b54',
			textTransform: 'uppercase',
			maxWidth: '560px',
		},
		childTitle: {
			fontSize: BreakpointsHelper.isMobile() ? '24px' : '50px',
			lineHeight: BreakpointsHelper.isMobile() ? '24px' : '50px',
			fontWeight: '900',
			color: '#74C5F0',
			textTransform: 'uppercase',
		},
		smallTitle: {
			marginTop: '15px',
			lineHeight: '27px',
			fontSize: '18px',
			color: 'rgb(18, 26, 68)',
			maxWidth: '560px',
		},
		image: {
			width: '100%',
		},
		item: {
			display: 'flex',
			alignItems: 'flex-start',
			justifyContent: 'center',
			flexDirection: 'column',
		},
		container: {
			display: 'flex',
			flexDirection: BreakpointsHelper.isMobile() ? 'column-reverse' : 'row',
		},
	}
	return (
		<div style={style.root}>
			<Grid container spacing={4} style={style.container}>
				<Grid item md={6} xs={12} style={style.item}>
					<div style={style.bigTitle} className="animate__animated animate__fadeIn animate__slower">
						Widzisz <span style={style.childTitle}>zagrożenie</span> to zareaguj
					</div>
					<div style={style.smallTitle} className="animate__animated animate__fadeIn animate__slower animate__delay-1s">
						Jeśli potrzebujesz pomocy w przypadku pożaru, wypadku drogowego lub innego nagłego zdarzenia, bezzwłocznie skontaktuj się z Centrum Powiadamiania Ratunkowego pod numer <b style={{ color: 'red' }}>112</b> lub <b style={{ color: 'red' }}>998</b>.
					</div>
				</Grid>
				<Grid item md={6} xs={12}>
					<img src={bannerImage} className="animate__animated animate__fadeIn animate__slower" alt="banner image" style={style.image} />
				</Grid>
			</Grid>
		</div>
	)
}

export default SmallSlider
