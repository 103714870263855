import React, { useEffect, useState } from 'react'
import logo from '../../../assets/configFiles/logo.jpg'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import MenuIcon from '@mui/icons-material/Menu'
import { EmojiEventsOutlined, LogoutOutlined, PersonOutlined, SupervisorAccountOutlined } from '@mui/icons-material'
import UserHelper from '../../../helpers/UserHelper/UserHelper'
import { Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import { useHistory } from 'react-router'

const Navigation = () => {
  const t = LocalStorageHelper.get('translation')
  const [open, setOpen] = useState(false)
  const path = window?.location?.pathname
  const history = useHistory()
  const style = {
    root: {
      paddingTop: 15,
      height: 'calc(100vh - 15px)',
      width: 70,
      overflow: 'hidden',
      position: 'fixed',
      display: BreakpointsHelper.isMobile() ? 'none' : 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      boxShadow: 'rgb(0 0 0 / 15%) 2px 2px 5px 2px'
    },
    rootMobileOpen: {
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      zIndex: 998,
      top: 0,
      width: '100%',
      height: '100vh',
      alignItems: 'start',
      background: 'white',
      justifyContent: 'start'
    },
    rootMobileClose: {
      display: 'none'
    },
    name: {
      display: 'flex',
      flexDirection: 'column',
      width: 235,
      padding: '15px'
    },
    social: {
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      padding: BreakpointsHelper.isMobile() ? '0 15px' : '0'
    },
    iconInsta: {
      color: '#e83464'
    },
    iconFace: {
      color: '#026ae3'
    },
    img: {
      marginLeft: '5px',
      width: '60px',
      marginBottom: '20px',
      marginTop: '20px',
      padding: open && '15px 0'
    },
    nav: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: BreakpointsHelper.isMobile() ? 'flex-start' : 'center',
      fontSize: BreakpointsHelper.isMobile() ? '16px' : '21px',
      padding: BreakpointsHelper.isMobile() ? '10px 15px' : '10px 15px',
      fontWeight: 700,
      color: '#324b54',
      lineHeight: '26px',
      textTransform: 'uppercase'
    },
    navMobile: {
      marginLeft: '10px',
      fontSize: '20px'
    },
    navigationList: {
      maxHeight: 'calc(100vh - 200px)',
      width: '100%',
      overflow: 'auto',
      marginTop: BreakpointsHelper.isMobile() && '50px'
    },
    burger: {
      position: 'fixed',
      top: '0',
      background: 'white',
      zIndex: 999,
      marginTop: open && '-20px',
      width: 'calc(100% - 20px)',
      height: '50px',
      boxShadow: 'rgb(0 0 0 / 15%) 2px 2px 5px 2px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 15px 0 5px',
      cursor: 'pointer'
    },
    menu: {
      width: 40,
      height: 40,
      color: '#324b54',
      cursor: 'pointer'
    }
  }
  const handleOpen = (url, target = '_self', outSide = null) => {
    if (BreakpointsHelper.isMobile()) setOpen(false)
    if (outSide) window.open(url, target)
    else history?.push(url)
  }
  useEffect(() => {
    if (open) document.body.classList.add('open')
    else if (document.body.classList.contains('open')) document.body.classList.remove('open')
  }, [open])
  return (
    <>
      <div className="Navigation"
           style={BreakpointsHelper.isMobile() ? open ? style.rootMobileOpen : style.rootMobileClose : style.root}>
        <Box sx={style.navigationList}>
          {!BreakpointsHelper.isMobile() && <img src={logo} alt="logo" style={style.img}/>}
          <Tooltip title="Rezerwacje" placement="right">
            <div className={`menuNav ${path === '/admin' && 'active'}`} onClick={() => handleOpen('/admin')}
                 style={style.nav}>
              <SupervisorAccountOutlined/>
              {BreakpointsHelper.isMobile() && <span style={style.navMobile}>Rezerwacje</span>}
            </div>
          </Tooltip>
          <Tooltip title="Aktualności" placement="right">
            <div className={`menuNav ${path === '/eventsAdmin' && 'active'}`} onClick={() => handleOpen('/eventsAdmin')}
                 style={style.nav}>
              <EmojiEventsOutlined/>
              {BreakpointsHelper.isMobile() && <span style={style.navMobile}>Aktualności</span>}
            </div>
          </Tooltip>
          <Tooltip title={t.globals.nav.profilePage} placement="right">
            <div className={`menuNav ${(path === '/profile' || path.search('previewEvent') > 0) && 'active'}`}
                 onClick={() => handleOpen('/profile')} style={style.nav}>
              <PersonOutlined/>
              {BreakpointsHelper.isMobile() && <span style={style.navMobile}>{t.globals.nav.profilePage}</span>}
            </div>
          </Tooltip>
          <Tooltip title={t.globals.nav.logout} placement="right">
            <div className="menuNav" onClick={() => UserHelper.logout()} style={style.nav}>
              <LogoutOutlined/>
              {BreakpointsHelper.isMobile() && <span style={style.navMobile}>{t.globals.nav.logout}</span>}
            </div>
          </Tooltip>
        </Box>
      </div>
      {
        BreakpointsHelper.isMobile() && (
          <div style={style.burger} onClick={() => setOpen(!open)}>
            <img src={logo} alt="logo" style={style.img}/>
            <MenuIcon style={style.menu}/>
          </div>
        )
      }
    </>
  )
}

export default Navigation
