import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import { Box, Button, Grid, Typography } from '@mui/material'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import { useEffect, useState } from 'react'
import { createOrUpdateReservations, deleteReservationById, getAllReservations } from '../../firebase'
import { useSnackbar } from 'notistack'

const AdminPage = () => {
  const { enqueueSnackbar } = useSnackbar()
  const user = LocalStorageHelper.get('user')
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    },
    title: {
      fontSize: '34px',
      fontWeight: '900',
      textTransform: 'uppercase',
      marginBottom: '10px',
    },
    description: {
      fontSize: '20px',
      fontWeight: '300',
      textAlign: 'justify',
    },
    contacts: {
      fontSize: '28px',
      fontWeight: '300',
      marginBottom: '20px',
      textAlign: 'justify',
    },
    img: {
      width: '100%',
      marginBottom: '20px',
    },
    boxData: {
      padding: '20px',
      boxShadow: 'rgba(0, 0, 0, 0.15) 2px 2px 5px 2px',
    }
  }
  const [data, setData] = useState(null)
  const setIsPay = (el, id) => {
    const tempData = el
    tempData.pay = true
    createOrUpdateReservations(tempData, id).then((res) => {
      if (res) {
        getAllReservations().then((res) => {
          if (res) {
            enqueueSnackbar('Ustawiono, że dziecko jest opłacone.', { variant: 'success' })
            setData(res)
          }
        })
      }
    })
  }
  const setIsCard = (el, id) => {
    const tempData = el
    tempData.card = true
    createOrUpdateReservations(tempData, id).then((res) => {
      if (res) {
        getAllReservations().then((res) => {
          if (res) {
            enqueueSnackbar('Ustawiono, że dziecko doniosło kartę zgłoszenia.', { variant: 'success' })
            setData(res)
          }
        })
      }
    })
  }
  const setIsNotCard = (el, id) => {
    const tempData = el
    tempData.card = false
    createOrUpdateReservations(tempData, id).then((res) => {
      if (res) {
        getAllReservations().then((res) => {
          if (res) {
            enqueueSnackbar('Ustawiono, że dziecko nie doniosło karty zgłoszenia.', { variant: 'success' })
            setData(res)
          }
        })
      }
    })
  }
  const setIsRegulations = (el, id) => {
    const tempData = el
    tempData.regulations = true
    createOrUpdateReservations(tempData, id).then((res) => {
      if (res) {
        getAllReservations().then((res) => {
          if (res) {
            enqueueSnackbar('Ustawiono, że dziecko nie doniosło karty zgłoszenia.', { variant: 'success' })
            setData(res)
          }
        })
      }
    })
  }
  const setIsNotRegulations = (el, id) => {
    const tempData = el
    tempData.regulations = false
    createOrUpdateReservations(tempData, id).then((res) => {
      if (res) {
        getAllReservations().then((res) => {
          if (res) {
            enqueueSnackbar('Ustawiono, że dziecko nie doniosło karty zgłoszenia.', { variant: 'success' })
            setData(res)
          }
        })
      }
    })
  }
  const setIsDocs = (el, id) => {
    const tempData = el
    tempData.docs = true
    createOrUpdateReservations(tempData, id).then((res) => {
      if (res) {
        getAllReservations().then((res) => {
          if (res) {
            enqueueSnackbar('Ustawiono, że dziecko nie doniosło karty zgłoszenia.', { variant: 'success' })
            setData(res)
          }
        })
      }
    })
  }
  const setIsNotDocs = (el, id) => {
    const tempData = el
    tempData.docs = false
    createOrUpdateReservations(tempData, id).then((res) => {
      if (res) {
        getAllReservations().then((res) => {
          if (res) {
            enqueueSnackbar('Ustawiono, że dziecko nie doniosło karty zgłoszenia.', { variant: 'success' })
            setData(res)
          }
        })
      }
    })
  }
  const setIsNotPay = (el, id) => {
    const tempData = el
    tempData.pay = false
    createOrUpdateReservations(tempData, id).then((res) => {
      if (res) {
        getAllReservations().then((res) => {
          if (res) {
            enqueueSnackbar('Ustawiono, że dziecko jest nie opłacone.', { variant: 'success' })
            setData(res)
          }
        })
      }
    })
  }
  const deleteKids = (id) => {
    deleteReservationById(id).then((res) => {
      getAllReservations().then((res) => {
        if (res) {
          enqueueSnackbar('Usunięto dziecko z bazy.', { variant: 'success' })
          setData(res)
        }
      })
    })
  }
  useEffect(() => {
    getAllReservations().then((res) => {
      if (res) {
        setData(res)
      }
    })
  }, [])
  return (
    <>
      <div style={style.root}>
        <Grid container spacing={4}>
          <Grid item md={12}>
            <Typography sx={style.title}>Rezerwacje dzieci na półkolonie</Typography>
          </Grid>
          {
            user?.role === 'admin' && (
              <>
                {
                  data?.length > 0
                    ? data?.map((el, index) => (
                    <Grid key={index} item md={12} xs={12}>
                      <Box sx={style.boxData} className={(el.pay && el.card && el.docs && el.regulations) ? 'payed' : 'notPayed'}>
                        <Typography sx={style.description}><b>Turnus:</b> {el.turnus}, <b>Zapłacił:</b> {el.pay ? 'Tak' : 'Nie'}, <b>Karta zgłoszenia:</b> {el.card ? 'Tak' : 'Nie'}, <b>Regulamin:</b> {el.regulations ? 'Tak' : 'Nie'}, <b>Umowa:</b> {el.docs ? 'Tak' : 'Nie'}</Typography>
                        <Typography sx={style.description}><b>Dziecko:</b> {el.name}, <b>Urodzony:</b> {el.date}</Typography>
                        <Typography sx={style.description}><b>Rodzic:</b> {el.parentName}, <b>Telefon:</b> {el.parentPhone}, <b>Email:</b> {el.parentEmail}</Typography>
                        <Typography sx={style.description}><b>Zaznacz, że:</b></Typography>
                        {
                          !el.pay && (
                            <Button
                              sx={{ marginTop: '10px', marginRight: '10px' }}
                              variant="contained"
                              onClick={() => setIsPay(el, el.id)}
                            >
                              Zapłacił
                            </Button>
                          )
                        }
                        {
                          el.pay && (
                            <Button
                              sx={{ marginTop: '10px', marginRight: '10px' }}
                              variant="contained"
                              onClick={() => setIsNotPay(el, el.id)}
                            >
                              Nie zapłacił
                            </Button>
                          )
                        }
                        {
                          !el.card && (
                            <Button
                              sx={{ marginTop: '10px', marginRight: '10px' }}
                              variant="contained"
                              onClick={() => setIsCard(el, el.id)}
                            >
                              Dał kartę zgłoszenia
                            </Button>
                          )
                        }
                        {
                          el.card && (
                            <Button
                              sx={{ marginTop: '10px', marginRight: '10px' }}
                              variant="contained"
                              onClick={() => setIsNotCard(el, el.id)}
                            >
                              Nie dał karty zgłoszenia
                            </Button>
                          )
                        }
                        {
                          !el.regulations && (
                            <Button
                              sx={{ marginTop: '10px', marginRight: '10px' }}
                              variant="contained"
                              onClick={() => setIsRegulations(el, el.id)}
                            >
                              Podpisał regulamin
                            </Button>
                          )
                        }
                        {
                          el.regulations && (
                            <Button
                              sx={{ marginTop: '10px', marginRight: '10px' }}
                              variant="contained"
                              onClick={() => setIsNotRegulations(el, el.id)}
                            >
                              Nie podpisał regulaminu
                            </Button>
                          )
                        }
                        {
                          !el.docs && (
                            <Button
                              sx={{ marginTop: '10px', marginRight: '10px' }}
                              variant="contained"
                              onClick={() => setIsDocs(el, el.id)}
                            >
                              Podpisał umowę
                            </Button>
                          )
                        }
                        {
                          el.docs && (
                            <Button
                              sx={{ marginTop: '10px', marginRight: '10px' }}
                              variant="contained"
                              onClick={() => setIsNotDocs(el, el.id)}
                            >
                              Nie podpisał umowy
                            </Button>
                          )
                        }
                        <Button
                          sx={{ marginTop: '10px', marginRight: '10px' }}
                          variant="contained"
                          onClick={() => deleteKids(el.id)}
                        >
                          Usuń dziecko z bazy
                        </Button>
                      </Box>
                    </Grid>
                  ))
                    : (
                      <Grid item md={12}>
                        <Typography>Brak dzieci zapisanych na półkolonie.</Typography>
                      </Grid>
                    )
                }
              </>
            )
          }
        </Grid>
      </div>
    </>
  )
}

export default AdminPage
