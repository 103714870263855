import * as yup from 'yup'
import ValidationHelper from './ValidationHelper'
import LocalStorageHelper from '../LocalStorageHelper/LocalStorageHelper'

export default {
  getValidationRules: (config) => {
    const t = LocalStorageHelper.get('translation')
    const schemaValidations = []
    if (!('required' in config)) {
      switch (config.type) {
        case 'password':
          schemaValidations.push({ type: 'required', params: [t.globals.validation.required] })
          schemaValidations.push({
            type: 'min',
            params: [8, t.globals.validation.password]
          })
          break
        case 'checkbox':
          break
        case 'checkboxes':
          break
        case 'date':
          break
        case 'editor':
          schemaValidations.push({ type: 'required', params: [t.globals.validation.required] })
          break
        case 'number':
          schemaValidations.push({ type: 'required', params: [t.globals.validation.required] })
          break
        case 'email':
          schemaValidations.push({ type: 'required', params: [t.globals.validation.required] })
          schemaValidations.push({ type: 'email', params: [t.globals.validation.email] })
          schemaValidations.push({
            type: 'min',
            params: [5, t.globals.validation.emailLength]
          })
          break
        default:
            break
      }
    }
    return schemaValidations
  },
  createYupSchema: (schema, config) => {
    const { name, validationType } = config
    if (!yup[validationType]) return schema
    let validator = yup[validationType]()
    const validationsData = ValidationHelper.getValidationRules(config)
    validationsData.forEach(validation => {
      const { params, type } = validation
      if (!validator[type]) return
      validator = validator[type](...params)
    })
    schema[name] = validator
    return schema
  },
}
