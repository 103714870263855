import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import { Helmet } from 'react-helmet'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import { Grid } from '@mui/material'
import image4 from '../../assets/images/4.jpg'
import bedzino from '../../assets/images/partners/bedzino.png'
import mlyny from '../../assets/images/partners/mlyny.png'
import hean from '../../assets/images/partners/hean.png'
import parol from '../../assets/images/partners/parol.png'
import aqua from '../../assets/images/partners/aqua.png'
import powiat from '../../assets/images/partners/powiat.png'
import SmallSlider from '../../components/molecules/SmallSlider/SmallSlider'
import Slider from '../../components/atoms/Slider/Slider'

const HomePage = () => {
  const t = LocalStorageHelper.get('translation')
  const style = {
    text: {
      fontSize: '18px',
      marginBottom: '20px',
      textAlign: 'justify',
    },
    textHeading: {
      fontSize: '34px',
      fontWeight: '900',
      textTransform: 'uppercase',
      marginBottom: '10px',
    },
    textHeadingCenter: {
      fontSize: '42px',
      fontWeight: '700',
      color: '#324b54',
      textTransform: 'uppercase',
      marginBottom: '0px',
      textAlign: 'center',
    },
    textCenter: {
      textAlign: 'center',
      fontSize: '18px',
      marginBottom: '20px',
    },
    textHeadingLeft: {
      fontSize: '18px',
      fontWeight: '900',
      marginBottom: '10px',
      textAlign: 'left',
    },
    box: {
      padding: '0px',
    },
    img: {
      width: '100%',
    },
  }
  const partners = [
    {
      name: 'Gmina Będzino',
      image: bedzino,
      link: 'https://bedzino.pl',
    },
    {
      name: 'Parol',
      image: parol,
      link: 'https://www.parol.com.pl/',
    },
    {
      name: 'Młyny Stoisław',
      image: mlyny,
      link: 'https://www.stoislaw.com.pl/pl/',
    },
    {
      name: 'Hean',
      image: hean,
      link: 'https://heanopakowania.pl',
    },
    {
      name: 'PowiatKoszaliński',
      image: powiat,
      link: 'https://www.powiat.koszalin.pl/',
    },
    {
      name: 'AquaProjekt',
      image: aqua,
      link: 'https://www.aqua.pl',
    },
  ]
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>OSP Mścice</title>
        <meta name="keywords" content=""/>
        <meta name="description" content=""/>
        <meta name="subject" content=""/>
        <meta name="copyright" content="OSP Mście"/>
        <meta name="language" content="PL"/>
        <meta name="robots" content="index,follow"/>
        <meta name="author" content="Your site, kontakt@your-site.pl"/>
        <meta name="designer" content="Your site, kontakt@your-site.pl"/>
        <meta name="copyright" content="Your site, kontakt@your-site.pl"/>
        <meta name="owner" content="Your site"/>
        <meta name="url" content="https://osmscice.pl"/>
        <meta name="identifier-URL" content="https://osmscice.pl"/>
        <meta name="category" content="Straż pożarna"/>
        <meta name="distribution" content="Global"/>
        <meta name="rating" content="General"/>
        <meta name="revisit-after" content="7 days"/>
        <meta httpEquiv="Expires" content="0"/>
        <meta httpEquiv="Pragma" content="no-cache"/>
        <meta httpEquiv="Cache-Control" content="no-cache"/>
        <meta name="og:title" content=""/>
        <meta name="og:type" content="website"/>
        <meta name="og:url" content="https://osmscice.pl"/>
        <meta name="og:site_name" content="OSP Mścice"/>
        <meta name="og:description" content=""/>
        <meta name="fb:page_id" content="ospmscice"/>
        <meta name="og:email" content=""/>
        <meta name="og:phone_number" content=""/>
        <meta name="og:locality" content="Mścice"/>
        <meta name="og:region" content="Zachodniopomorskie"/>
        <meta name="og:postal-code" content=""/>
        <meta name="og:country-name" content="Polska"/>
        <meta property="og:type" content="website"/>
        <meta name="apple-mobile-web-app-capable" content="yes"/>
        <meta content="yes" name="apple-touch-fullscreen"/>
        <meta name="apple-mobile-web-app-status-bar-style" content="black"/>
        <meta name="format-detection" content="telephone=no"/>
      </Helmet>
      <Slider />
      <div>
        <Box sx={{ paddingBottom: '50px' }}>
          <Grid container spacing={4}>
            <Grid item md={6} xs={12}>
              <img src={image4} alt="image4" style={style.img} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography sx={style.textHeading}>Parę słów o nas</Typography>
              <Typography sx={style.text}>
                Jesteśmy grupą oddanych i zgranych ochotników, którzy z poświęceniem i pasją służą dla dobra innych. Jesteśmy gotowi do natychmiastowej akcji, by chronić życie i mienie mieszkańców.
                Codziennie trenujemy, doskonalimy umiejętności i angażujemy się w działania profilaktyczne, aby minimalizować ryzyko pożarowe. Nasza praca nie ogranicza się tylko do reagowania na sytuacje kryzysowe - organizujemy szkolenia dla społeczności, uczymy pierwszej pomocy i wspieramy lokalne inicjatywy.
                Nasza więź z mieszkańcami jest silna. Wspólnie przeżywamy trudności i radości, jesteśmy obecni na lokalnych wydarzeniach i aktywnie uczestniczymy w życiu społeczności. To zaangażowanie sprawia, że jesteśmy dumni z naszej roli strażaków i z pasją służymy jako wzór dla innych.
                Wszystko, co robimy, wynika z naszego wspólnego celu - chronić i pomagać. Ochotnicza Straż Pożarna to nie tylko zespół ratunkowy, ale również rodzina. Jesteśmy świadomi, że nasza praca ma ogromne znaczenie dla bezpieczeństwa i spokoju naszej społeczności. To dla niej oddajemy nasz czas i serce, czyniąc Mścice bezpiecznym i przyjaznym miejscem do życia.
              </Typography>

            </Grid>
          </Grid>
        </Box>
        <SmallSlider />
        <Box sx={style.box}>
          <Grid container spacing={10}>
            <Grid item md={12} xs={12}>
              <Typography sx={style.textHeadingCenter}>Nasi partnerzy</Typography>
            </Grid>
            {
              partners?.map((el, index) => {
                return (
                  <Grid key={index} item md={2} xs={12}>
                     <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                       <a href={el?.link} style={{ textDecoration: 'none' }}>
                         <img src={el?.image} alt={el?.name} style={{ width: '100%' }}/>
                       </a>
                     </Box>
                  </Grid>
                )
              })
            }
          </Grid>
        </Box>
      </div>
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: BreakpointsHelper.isMobile() ? 'flex-start' : 'center',
          justifyContent: 'center',
          flexDirection: BreakpointsHelper.isMobile() && 'column',
          height: '150px',
          width: '100%',
        }}
      >
        <Typography
          sx={{
            marginRight: '10px',
            marginLeft: '10px',
            cursor: 'pointer'
          }}
        >
          @ {new Date().getFullYear()} wszelkie prawa zastrzeżone przez OSP Mścice. Strona stworzona przez &nbsp;
          <a href="https://your-site.pl" target="_blank" rel="noreferrer">Your site</a>.
          Wszelkie informacje dostępne pod: <a href="#" target="_blank" rel="noreferrer">{t?.homePage?.footer.desc2}</a> oraz <a href="#" target="_blank" rel="noreferrer">{t?.homePage?.footer.desc3}</a>
        </Typography>
      </Box>
    </>
  )
}

export default HomePage
