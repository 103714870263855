import { Grid, Typography } from '@mui/material'
import image2 from '../../assets/images/2.jpg'

const ContactPage = () => {
  const style = {
    title: {
      fontSize: '34px',
      fontWeight: '900',
      textTransform: 'uppercase',
      marginBottom: '10px',
    },
    description: {
      fontSize: '20px',
      fontWeight: '300',
      marginBottom: '20px',
      textAlign: 'justify',
    },
    contacts: {
      fontSize: '20px',
      fontWeight: '300',
      marginBottom: '20px',
      textAlign: 'justify',
    },
    img: {
      width: '100%',
    }
  }
  return (
    <Grid container spacing={16}>
      <Grid item md={6} xs={12}>
        <Typography sx={style.title}>Skontaktuj się  z nami</Typography>
        <Typography sx={style.description}>
          Jeśli potrzebujesz pomocy w przypadku pożaru, wypadku drogowego lub innego nagłego zdarzenia, bezzwłocznie skontaktuj się z Centrum Powiadamiania Ratunkowego pod numer <b>112</b> lub <b>998</b>.
        </Typography>
        <Typography sx={style.description}>
          <b>Uwaga: Numer kontaktowy do jednostki nie stanowi drogi alarmowania!</b>
        </Typography>
        <Typography sx={style.title}>Dane kontaktowe</Typography>
        <Typography sx={style.contacts}>
          <b>Adres:</b><br/>
          Ochotnicza Straż Pożarna, ul. Koszalińska 104, 76-031 Mscice, Poland<br/>
          <b>Telefon:</b><br/>
          Prezes: 795 160 369<br/>
          Wiceprezes: 668 822 926<br/>
          Naczelnik: 505 126 844<br/>
          Koordynator MDP: 666 242 421<br/>
          <b>Email:</b> <br/>
          ospmscice@gmail.com<br/>
          mdp@ospmscice.pl<br/>
          <b>Inne:</b> <br/>
          REGON: 330577528<br/>
          NIP: 4990151087<br/>
          KRS: 0000160528<br/>
          <b>Numer konta do wpłat na działalność statutową</b><br/>PKO BP: 03 1020 2791 0000 7102 0351 8586<br/>
        </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
        <img src={image2} alt="image2" style={style.img} />
      </Grid>
      <Grid item md={12} xs={12}>
        <iframe
            title="maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d291.60036151817206!2d16.088041276555014!3d54.218977527956945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470032617fcc011b%3A0x533e66cbafd75840!2sOchotnicza%20Stra%C5%BC%20Po%C5%BCarna!5e0!3m2!1sen!2spl!4v1683445595048!5m2!1sen!2spl"
          width="100%" height="550" style={{ border: 0, filter: 'grayscale(100%) invert(92%) contrast(83%)', marginBottom: '50px' }} allowFullScreen="" loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"></iframe>
      </Grid>
    </Grid>
  )
}

export default ContactPage
