import React, { useState } from 'react'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import logo from '../../../assets/configFiles/logo.jpg'
import Box from '@mui/material/Box'
import MenuIcon from '@mui/icons-material/Menu'
import { Button, Menu, MenuItem, Typography } from '@mui/material'

const NavigationPreview = () => {
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const openMenu = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const style = {
    nav: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      margin: '0 auto',
      height: '90px',
      background: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      zIndex: 999,
      boxShadow: '2px 2px 20px 10px rgba(0, 0, 0, 0.02)'
    },
    box: {
      margin: '0 auto',
      maxWidth: '1440px',
      padding: '0 20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    logo: {
      height: '55px',
      cursor: 'pointer'
    },
    logoOpen: {
      height: BreakpointsHelper.isMobile() ? '55px' : '100%',
      marginLeft: BreakpointsHelper.isMobile() ? '0' : '35px',
      position: 'relative',
      left: '-5px',
      bottom: '-3px'
    },
    buttons: {
      display: 'flex',
      alignItems: 'center'
    },
    burgerOpen: {
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      position: 'relative',
      right: '-5px',
      bottom: '-2px',
      marginBottom: '30px'
    },
    elementNav: {
      fontSize: '18px',
      marginLeft: BreakpointsHelper.isMobile() ? '0' : '40px',
      cursor: 'pointer',
      border: BreakpointsHelper.isMobile() && '1px solid black',
      padding: BreakpointsHelper.isMobile() && '20px',
      textAlign: BreakpointsHelper.isMobile() && 'center',
      marginBottom: BreakpointsHelper.isMobile() && '20px'
    },
    menuRight: {
      display: 'flex'
    }
  }
  const openLink = (link) => window.open(link, '_self')
  const generateMenu = () => {
    const pages = [
      {
        name: 'O nas',
        link: '/about',
        ul: [
          {
            name: 'Historia straży pożarnej',
            link: '/history',
          },
          {
            name: 'Członkowie',
            link: '/ourTeam',
          },
          {
            name: 'Młodzieżowa drużyna pożarnicza',
            link: '/ourChildTeam',
          },
        ],
      },
      {
        name: 'Oferta',
        link: '/offer',
      },
      {
        name: 'Aktualności',
        link: '/events',
      },
      {
        name: 'Kontakt',
        link: '/contact',
      },
      {
        name: 'Strefa strażaka',
        link: '/login',
      },
    ]
    return (
      <header style={{ display: 'flex' }}>
        <Box sx={{ flexGrow: 1, display: { md: 'flex' } }}>
          {
            pages.map((page, index) => {
              if (page?.ul?.length > 0) {
               return (
                 <>
                   <Button
                     id="basic-button"
                     aria-controls={openMenu ? 'basic-menu' : undefined}
                     aria-haspopup="true"
                     aria-expanded={openMenu ? 'true' : undefined}
                     onClick={handleClick}
                     sx={{ my: 2, display: 'block', ml: 4, fontSize: '16px' }}
                   >
                     {page.name}
                   </Button>
                   <Menu
                     id="basic-menu"
                     anchorEl={anchorEl}
                     open={openMenu}
                     onClose={handleClose}
                     MenuListProps={{
                       'aria-labelledby': 'basic-button',
                     }}
                   >
                     { page?.ul?.map((element, key) => <MenuItem sx={{ textTransform: 'uppercase' }} key={key} onClick={() => openLink(element.link)}>{element.name}</MenuItem>) }
                   </Menu>
                 </>
               )
              }
              return (
                <Button
                  key={index}
                  onClick={() => openLink(page.link)}
                  variant={pages.length - 1 === index ? 'contained' : 'text'}
                  className={window.location.pathname === page.link && 'active'}
                  sx={{ my: 2, display: 'block', ml: 4, fontSize: '16px' }}
                >
                  {page.name}
                </Button>
              )
            })
          }
        </Box>
      </header>
    )
  }
  return (
    <div style={style.nav} className="navigation">
      <Box sx={style.box}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} onClick={() => openLink('/home')} alt={'logo'} style={style.logo}/>
          <Typography onClick={() => openLink('/home')} sx={{ fontWeight: '600', color: 'rgba(0, 0, 0, 0.85)', fontSize: '22px', cursor: 'pointer' }}>
            OSP Mścice
          </Typography>
        </Box>
        <Box sx={style.menuRight}>
          {
            BreakpointsHelper.isMobile()
              ? (
                <div style={style.burger} onClick={() => setOpen(!open)}>
                  <MenuIcon style={style.menu}/>
                </div>
              )
              : generateMenu()
          }
          {
            open && (
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: '999',
                  background: 'white',
                  top: '90px',
                  left: '0',
                  height: 'calc(100vh - 90px)',
                  width: 'calc(100% - 40px)',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '20px'
                }}
              >
                {generateMenu()}
              </Box>
            )
          }
        </Box>
      </Box>
    </div>
  )
}

export default NavigationPreview
