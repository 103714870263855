import PropTypes from 'prop-types'
import NavigationPreview from '../../components/molecules/NavigationPreview/NavigationPreview'
import { Box } from '@mui/material'

const PreviewLayout = (props) => {
  const Component = props.component
  const { location } = props
  const style = {
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'start',
      flexDirection: 'column',
      padding: '90px 0 0 0',
      width: '100%'
    },
    component: {
      maxWidth: '1440px',
      margin: '0 auto',
      width: 'calc(100% - 40px)',
      padding: '80px 20px 0 20px',
    },
  }
  return (
    <div style={style.root}>
      <NavigationPreview location={location}/>
      <Box sx={style.component}>
        <Component location={location}/>
      </Box>
    </div>
  )
}

PreviewLayout.defaultProps = {
  location: false
}

PreviewLayout.propsType = {
  location: PropTypes.object
}

export default PreviewLayout
