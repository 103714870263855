import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import { Button, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import Dialog from '../../components/atoms/Dialog/Dialog'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import Box from '@mui/material/Box'
import { deleteEventById, getAllDataFromPath, updateOrCreateDocument } from '../../firebase'
import { useSnackbar } from 'notistack'

const AdminEventsPage = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = useState(false)
  const [data, setData] = useState(null)
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    },
    title: {
      fontSize: '34px',
      fontWeight: '900',
      textTransform: 'uppercase',
      marginBottom: '10px',
    },
    description: {
      fontSize: '20px',
      fontWeight: '300',
      textAlign: 'justify',
    },
    contacts: {
      fontSize: '28px',
      fontWeight: '300',
      marginBottom: '20px',
      textAlign: 'justify',
    },
    img: {
      width: '100%',
      marginBottom: '20px',
    },
    boxData: {
      padding: '20px',
      boxShadow: 'rgba(0, 0, 0, 0.15) 2px 2px 5px 2px',
    }
  }
  const addEvent = () => {
    setOpen(true)
  }
  const renderDialog = () => {
    const today = new Date()
    const todayYear = today.getFullYear()
    const todayMonth = (today.getMonth() + 1).toString().length > 1 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1)
    const todayDay = (today.getDate()).toString().length > 1 ? today.getDate() : '0' + today.getDate()
    const formData = {
      elements: [
        {
          type: 'text',
          name: 'title',
          label: 'Tytuł wydarzenia',
          validationType: 'string',
        },
        {
          type: 'date',
          name: 'date',
          label: 'Data wydarzenia',
          value: `${todayYear}-${todayMonth}-${todayDay}`,
          validationType: 'string',
        },
        {
          type: 'text',
          name: 'descriptionSmall',
          label: 'Krótki opis wydarzenia',
          validationType: 'string',
        },
        {
          type: 'editor',
          name: 'description',
          label: 'Opis wydarzenia',
          validationType: 'string',
        },
        {
          type: 'files',
          name: 'files',
          label: 'Zdjęcia z wydarzenia',
        },
        {
          type: 'button',
          value: 'Dodaj wydarzenie',
        }
      ],
    }
    const saveNewEvent = (e) => {
      const id = e?.files?.length > 0 && e?.files[0].replace('https://firebasestorage.googleapis.com/v0/b/ospmscice-e11fa.appspot.com/o/events%2F', '').split('?')[0].split('%2F')[0]
      if (id) {
        updateOrCreateDocument('events', id, e).then(() => {
          setOpen(false)
          enqueueSnackbar('Dodano wydarzenie poprawnie.', { variant: 'success' })
          getAllEvents()
        })
      } else {
        enqueueSnackbar('Problem z dodaniem plików.', { variant: 'error' })
      }
    }
    return (
      <Box sx={{ width: BreakpointsHelper.isMobile() ? '100%' : '50%' }}>
        <FormGenerator data={formData} submit={(e) => saveNewEvent(e)} />
      </Box>
    )
  }
  const getAllEvents = () => {
    getAllDataFromPath('events').then((res) => {
      if (res) {
        res.sort((a, b) => new Date(b.data.date) - new Date(a.data.date))
        setData(res)
      }
    })
  }
  const editEvent = (el) => {
    props.location.history.push(`/editEvent/${el.id}`)
  }
  const deleteEvent = (id) => {
    deleteEventById(id).then(() => {
      enqueueSnackbar('Usunięto wydarzenie poprawnie.', { variant: 'success' })
      getAllEvents()
    })
  }
  useEffect(() => {
    getAllEvents()
  }, [])
  return (
    <>
      <div style={style.root}>
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <Typography sx={style.title}>Aktualności</Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Button variant="contained" onClick={() => addEvent()}>Dodaj nowe wydarzenie</Button>
            </Box>
          </Grid>
          <Grid item md={12} xs={12}>
            {
              data?.map((el, index) => {
                return (
                  <Box sx={{ display: 'flex', flexDirection: 'column', padding: '50px', boxShadow: '2px 2px 10px 1px rgba(0,0,0,.3)', marginBottom: '30px' }} key={index}>
                    <Grid container spacing={4}>
                      <Grid item md={4} xs={12}>
                        <img src={el.data.files[0]} alt={el.data.tile} style={{ maxHeight: '100%', width: '100%' }} />
                      </Grid>
                      <Grid item md={8} xs={12}>
                        <Typography sx={{ marginBottom: '5px' }}>{el.data.date}</Typography>
                        <Typography sx={{ fontWeight: '600', marginBottom: '10px', fontSize: '26px' }}>{el.data.title}</Typography>
                        <Typography sx={{ fontSize: '20px', marginBottom: '20px' }}>{el.data.descriptionSmall}</Typography>
                        <Box sx={{ display: 'flex', marginTop: '10px' }}>
                          <Button
                            sx={{ width: 'auto', marginRight: '20px' }}
                            variant="contained"
                            onClick={() => editEvent(el)}
                          >
                            Edytuj
                          </Button>
                          <Button
                            sx={{ width: 'auto', background: 'red' }}
                            variant="contained"
                            onClick={() => deleteEvent(el.id)}
                          >
                            Usuń
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )
              })
            }
          </Grid>
        </Grid>
        {
          open && (
            <Dialog title="Dodanie nowego wydarzenia" open={open} content={renderDialog()} onClose={() => setOpen(false)} />
          )
        }
      </div>
    </>
  )
}

export default AdminEventsPage
