import { BrowserRouter as Router, Switch } from 'react-router-dom'
import MainLayout from './layouts/MainLayout/MainLayout'
import { Redirect } from 'react-router'
import './assets/scss/App.scss'
import { PublicRoute } from './router/PublicRoute/PublicRoute'
import LoginPage from './pages/LoginPage/LoginPage'
import SimpleLayout from './layouts/SimpleLayout/SimpleLayout'
import { PrivateRoute } from './router/PrivateRoute/PrivateRoute'
import HomePage from './pages/HomePage/HomePage'
import PreviewLayout from './layouts/PreviewLayout/PreviewLayout'
import RegisterPage from './pages/RegisterPage/RegisterPage'
import ProfilePage from './pages/ProfilePage/ProfilePage'
import AdminPage from './pages/AdminPage/AdminPage'
import RememberPage from './pages/RememberPage/RememberPage'
import LangHelper from './helpers/LangHelper/LangHelper'
import OfferPage from './pages/OfferPage/OfferPage'
import EventsPage from './pages/EventsPage/EventsPage'
import ContactPage from './pages/ContactPage/ContactPage'
import SaveKidsPage from './pages/SaveKidsPage/SaveKidsPage'
import HistoryPage from './pages/HistoryPage/HistoryPage'
import TeamPage from './pages/TeamPage/TeamPage'
import TeamChildPage from './pages/TeamChildPage/TeamChildPage'
import EventPage from './pages/EventPage/EventPage'
import AdminEventsPage from './pages/AdminEventsPage/AdminEventsPage'
import EditEventPage from './pages/EditEventPage/EditEventPage'

const App = () => {
  LangHelper.setDefaultLanguage()
  return (
    <Router basename={'/'}>
      <Switch>
        <PublicRoute exact path={'/'} component={() => <Redirect to={'/home'}/>} layout={SimpleLayout}/>
        <PublicRoute path={'/home'} component={HomePage} layout={PreviewLayout}/>
        <PublicRoute path={'/offer'} component={OfferPage} layout={PreviewLayout}/>
        <PublicRoute path={'/events'} component={EventsPage} layout={PreviewLayout}/>
        <PublicRoute path={'/event/:id'} component={EventPage} layout={PreviewLayout}/>
        <PublicRoute path={'/contact'} component={ContactPage} layout={PreviewLayout}/>
        <PublicRoute path={'/saveKids'} component={SaveKidsPage} layout={PreviewLayout}/>
        <PublicRoute path={'/history'} component={HistoryPage} layout={PreviewLayout}/>
        <PublicRoute path={'/ourTeam'} component={TeamPage} layout={PreviewLayout}/>
        <PublicRoute path={'/ourChildTeam'} component={TeamChildPage} layout={PreviewLayout}/>
        <PublicRoute path={'/login'} component={LoginPage} layout={SimpleLayout}/>
        <PublicRoute path={'/register'} component={RegisterPage} layout={SimpleLayout}/>
        <PublicRoute path={'/reset'} component={RememberPage} layout={SimpleLayout}/>
        <PublicRoute path={'/eventsAdmin'} component={AdminEventsPage} layout={MainLayout}/>
        <PublicRoute path={'/editEvent/:id'} component={EditEventPage} layout={MainLayout}/>
        <PrivateRoute path={'/admin'} component={AdminPage} layout={MainLayout}/>
        <PrivateRoute path={'/profile'} component={ProfilePage} layout={MainLayout}/>
      </Switch>
    </Router>
  )
}

export default App
