import { Button, Grid, Typography } from '@mui/material'
import image7 from '../../assets/images/7.jpg'
import image8 from '../../assets/images/8.jpg'
import image9 from '../../assets/images/9.jpg'
import image10 from '../../assets/images/10.jpg'
import image11 from '../../assets/images/11.jpg'
import wych from '../../assets/images/wych.jpg'
import image14 from '../../assets/images/14.jpg'
import koszulka from '../../assets/images/koszulka.jpg'
import form from '../../assets/images/form.jpg'
import play from '../../assets/images/play.jpg'
import fotorelacja from '../../assets/images/foto.jpg'
import Box from '@mui/material/Box'

const OfferPage = () => {
  const style = {
    title: {
      fontSize: '34px',
      fontWeight: '900',
      textTransform: 'uppercase',
      marginBottom: '10px',
    },
    description: {
      fontSize: '20px',
      fontWeight: '300',
      marginBottom: '20px',
      textAlign: 'justify',
    },
    descriptionCenter: {
      fontSize: '20px',
      fontWeight: '300',
      marginBottom: '20px',
      textAlign: 'center',
      minHeight: '150px',
      marginTop: '30px',
    },
    contacts: {
      fontSize: '28px',
      fontWeight: '300',
      marginBottom: '20px',
      textAlign: 'justify',
    },
    img: {
      width: '100%',
      marginBottom: '20px',
    }
  }
  return (
    <Grid container spacing={16}>
      <Grid item md={6} xs={12}>
        <Typography sx={style.title}>
          Półkolonie letnie
        </Typography>
        <Typography sx={style.description}>
          To kolejne fascynujące i ekscytujące podróże ku przygodom, gdzie nie zabraknie spotkań z rówieśnikami, ganiania, skakania i śmiechu. Radości z poznawania i odkrywania świata i jego dziwów. Z dala od zgiełku szkoły i codzienności będziemy mogli naładować nasze baterie szalejąc na świeżym powietrzu. Będzie magicznie, twórczo i wesoło, a ograniczać nas będzie tylko wyobraźnia.
        </Typography>
        <Button
          sx={{ marginBottom: '20px', padding: '8px 20px' }}
          variant="contained"
          onClick={() => window.open('/saveKids', '_self')}
        >
          Zapisz dziecko na połkolonie
        </Button>
        <Typography sx={style.description}>
          <b>Co się dzieje na półkoloniach?</b>
        </Typography>
        <Typography sx={style.description}>
          Jesteśmy dumni z faktu, że od lat organizujemy letnie półkolonie dla dzieci w wieku od 7 do 13 lat. Nasz kompleks znajduje się w urokliwej okolicy, otoczonej pięknymi krajobrazami, co pozwala nam zapewnić dzieciom niezapomniane wrażenia i wspaniałe doświadczenia, które pozostaną w ich pamięci na długo.
        </Typography>
        <Typography sx={style.description}>
          Forma wypoczynku skierowana jest do tych, którzy lubią działać kreatywnie i manualnie. Na naszych koloniach będziemy mieli okazję zmierzyć się z nowymi wyzwaniami, dyscyplinami sportowymi, doskonalić swoje umiejętności, rozwijać się poprzez połączenie zabawy i nauki, nawiązać nowe znajomości a przede wszystkim dobrze się bawić. Zajęcia dostosowane są do wieku, poziomu umiejętności i predyspozycji uczestników (niewymagana jest wcześniejsza znajomość wspinaczki ani którejkolwiek z proponowanych dyscyplin sportowych).        </Typography>
        <Typography sx={style.description}>
          <b>W programie półkolonii oferujemy między innymi:</b>
        </Typography>
        <Typography sx={style.description}>
          <b>Młodzi Piraci z Karaibów</b> jest to zabawa w poszukiwaniu ukrytego skarbu piratów. Zabawa rozpoczyna się w porcie w Zatoce Optymistów gdzie uczestnicy znajdują skrawek mapy Starego Kapitana Jacka, która skrywa informacje o ukrytym skarbie piratów.  W trakcie rejsu po akwenie na pokładzie statku mali piraci poszukują wskazówek, które doprowadzą ich do miejsc gdzie czekają na nich zadania do wykonania. Po wykonaniu każdego zadania uczestnicy otrzymują kolejne klucze do skarbu.
        </Typography>
        <Typography sx={style.description}>
          Na zakończenie gry, w nagrodę za zaliczenie wszystkich zadań, uczestnicy będą dysponowali wszystkimi kluczami, dzięki którym otworzą skarb
          - wspólną nagrodę pełną pirackich niespodzianek.
        </Typography>
        <Typography sx={style.description}>
          Dzieci szukając kolejnych części mapy i skarbu w radosnej i luźnej atmosferze wśród  rówieśników poznają podstawy żeglarstwa, zasady bezpieczeństwa nad wodą - <b>wracają do domów zmęczone i szczęśliwe.</b>
        </Typography>
        <Typography sx={style.description}>
          <b>Zdobywcy Dżungli</b> to świetna forma rozrywki sportowej i rekreacji dla dzieci w każdym wieku polegająca na przejście toru z przeszkodami zawieszonymi na drzewach.
        </Typography>
        <Typography sx={style.description}>
          Jest to niecodzienna forma rekreacji i sportu będąca doskonałym sposobem aby zachęcić dzieci do aktywności fizycznej. Dzieci podczas wspólnej zabawy integrują się w grupie. Zdobywając dżunglę – wiele zadań dzieci wykonują razem ucząc się z nami komunikacji interpersonalnej i współpracy.
        </Typography>
        <Typography sx={style.description}>
          Trasy Parku Linowego rozpięte są pomiędzy drzewami, przeszkody są bardzo różne, czasem wymagają więcej równowagi, czasem więcej siły, a czasem jedynie odwagi.
          Do dyspozycji Waszych pociech pozostają trzy trasy, więc każdy znajdzie coś dla siebie.
        </Typography>
        <Typography sx={style.description}>
          Bezpieczeństwo w Parku Linowym zapewnia profesjonalny sprzęt asekuracyjny, w który uczestnicy zostają wyposażeni przez Instruktora. Każdy zostaje gruntowanie przeszkolony z zasad bezpiecznego poruszania się po Parku, a następnie przeegzaminowany przez Instruktora w celu weryfikacji umiejętności i wyeliminowania błędów. Taki system pozwala na samodzielne poruszanie się po trasach Parku wszystkim uczestnikom atrakcji (najmłodszym oczywiście pod okiem dorosłego). Nad bezpieczeństwem i poprawnością przejścia czuwa Instruktor.
        </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
        <img src={form} alt="image13" style={style.img} />
        <img src={image8} alt="image8" style={style.img} />
        <img src={image11} alt="image11" style={style.img} />
      </Grid>
      <Grid item md={6} xs={12}>
        <img src={image14} alt="image11" style={style.img} />
        <img src={play} alt="image11" style={style.img} />
      </Grid>
      <Grid item md={6} xs={12}>
        <Typography sx={style.description}>
          <b>Dzień małego strażaka</b><br/>
          Kto nie marzył, by zostać, choć na chwilę, strażakiem? W dniu małego strażaka Wasze pociechy na jeden dzień przeobrażą
          się w strażaka ochotniczej straży pożarnej. Ten dzień będzie pełen nauki przez zabawę. Wasze pociechy poznają tajniki zawodu strażaka, zapoznają się ze sprzętem na którym pracują nasi druhowie,  wezmą udział w szkoleniu z udzielania pierwszej pomocy przedmedycznej, a na koniec dnia czeka na nich rywalizacja sportowa w zawodach pożarniczych
        </Typography>
        <Typography sx={style.description}>
          <b>Wodne szaleństwo</b> to czas, podczas którego Państwa pociechy w gronie rówieśników  będą się świetnie bawić w tropikalnym klimacie przez cały dzień, to zawody na zjeżdżalniach, sportowe rywalizacje i bitwy wodne oraz wiele, wiele innych niespodzianek które czekają na nie w Parku Wodnym w Koszalinie
        </Typography>
        <Typography sx={style.description}>
          <b>Sport i animacje</b><br/>
          Blok zajęć Sportowo-Animacyjnych zapewnia dzieciom możliwość ruchu i wspólnej zabawy. W trakcie weźmiemy udział w grach terenowych na świeżym powietrzu, turnieju gier planszowych. Uczestnicy będą mieli możliwość korzystania z lokalnego boiska i placów zabaw.
        </Typography>
        <Typography sx={style.description}>
          Animatorzy zadbają o dobrą atmosferę i zapewnią różnorodne, ciekawe gry i zabawy dostosowane do wieku i możliwości uczestniczek. Te zajęcia są okazją do wspólnej zabawy, aktywności fizycznej oraz do wyładowania emocji.
        </Typography>
        <Typography sx={style.description}>
          <b>Ponadto w programie:</b> gry terenowe wspierające umiejętności współpracy, prace techniczne, wycieczki rowerowe i mnóstwo aktywności na zewnątrz, gry na orientację, kompas, mapa, jak rozpalić ognisko, basen i pływanie, gra w piłkę, zabawy na placu zabaw, wodne bitwy strażackie, konkursy marynistyczne.        </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
        <img src={image9} alt="image9" style={style.img} />
        <img src={image10} alt="image10" style={style.img} />
      </Grid>
      <Grid item md={6} xs={12}>
        <Typography sx={style.description}>
          <b>Jak wygląda dzień w czasie półkolonii?</b>
        </Typography>
        <Typography sx={style.description}>
          W pierwszym dniu turnusu dzieci dostają od nas naklejkę z imieniem. Od początku
          staramy się zaznajomić je z nowym miejscem.
        </Typography>
        <Typography sx={style.description}>
          <b>8:00 - 9:00</b><br/> Wspólna rywalizacja w gry planszowe lub stołowe, rysowanie przy stole
          plastycznym lub zmagania w minigrach na naszych boiskach.
        </Typography>
        <Typography sx={style.description}>
          <b>9:00</b><br/> Śniadanie w formie bufetu poprzedzone niezbędną higieną. Zapewniamy też produkty
          dla dzieci, które wymagają specjalnej diety ze względów zdrowotnych.
        </Typography>
        <Typography sx={style.description}>
          <b>9:30</b><br/> Sprawdzanie listy obecności po posiłku oraz podział na grupy do poszczególnych
          zajęć. Dzieci dobieramy pod względem wieku oraz wzajemnych relacji (rodzeństwo,
          koledzy).
        </Typography>
        <Typography sx={style.description}>
          <b>10:00</b><br/> Zajęcia fakultatywne zgodnie z planem półkolonii. Poszczególne atrakcje i podział na grupy
          są dostosowane do preferencji i możliwości dzieci. Po trzech godzinach aktywności
          kończymy pierwszy cykl zajęć.
        </Typography>
        <Typography sx={style.description}>
          <b>13:30</b><br/> Obiad poprzedzony dokładną higieną. Posiłki składają się z zupy (podawanej do
          stołu przez wychowawców) i drugiego dania. Chętni mogą korzystać z dokładek. Dla dzieci z
          alergiami podawane są specjalnie przygotowane dania, dlatego prosimy rodziców i
          opiekunów o wcześniejsze zgłaszanie takiej potrzeby.
        </Typography>
        <Typography sx={style.description}>
          <b>14:00</b><br/> Po posiłku przewidziany jest czas na odpoczynek.
        </Typography>
        <Typography sx={style.description}>
          <b>14:30</b><br/> Rozpoczęcie drugiego cyklu zajęć. Część grup kontynuuje wcześniejsze zajęcia, a
          pozostali bawią się swobodnie pod okiem wychowawców.
        </Typography>
        <Typography sx={style.description}>
          <b>16:00</b><br/> Rodzice i opiekunowie odbierają pociechy. Prosimy zgłaszać każde
          wyjście dziecka jednemu z wychowawców. Jeżeli maluch będzie odbierany przez kogoś
          innego, to wymagana jest specjalna zgoda i zgłoszenie przed rozpoczęciem turnusu.
        </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
        <Typography sx={style.description}>
          <b>Jakie są limity wiekowe?</b>
        </Typography>
        <Typography sx={style.description}>
          Półkolonie przeznaczone są dla dzieci i młodzieży w wieku od 7 do 13 lat.
          Na 15 uczestników przypada jeden opiekun posiadający niezbędne doświadczenie i kwalifikacje. Cała kadra półkolonii w większości składa się z nauczycieli i trenerów sportu
        </Typography>
        <Typography sx={style.description}>
          <b>Nasza kadra </b>
        </Typography>
        <Typography sx={style.description}>
          Nasza kadra składa się z doświadczonych instruktorów, pedagogów którzy dołożą wszelkich starań aby Państwa dzieci bawiły się wyśmienicie, a przede wszystkim bezpiecznie.
        </Typography>
        <Typography sx={style.description}>
          Zależy nam na tym, aby dać najmłodszym możliwość rozwoju oraz stworzyć miejsce, które wspiera oraz pomaga w odkrywaniu wewnętrznego potencjału. Z tego też powodu w naszej ofercie znajdują się zajęcia o niezwykle zróżnicowanej tematyce.
        </Typography>
        <Typography sx={style.description}>
          Naszym celem jest zaszczepienie podopiecznym zamiłowania do aktywności i wpisanie tego w ich DNA.
        </Typography>
        <Typography sx={style.description}>
          <b>Lokalizacja</b>
        </Typography>
        <Typography sx={style.description}>
          Ochotnicza Straż Pożarna w Mścicach<br/>
          ul. Koszalińska 104<br/>
          76 – 031 Mścice<br/>
        </Typography>
        <Typography sx={style.description}>
          <b>Terminy półkolonii</b>
        </Typography>
        <Typography sx={style.description}>
          Turnus I<br/>		26.06.2023 – 30.06.2023<br/><br/>
          Turnus II<br/>	03.07.2023 – 07.07.2023<br/><br/>
          Turnus III<br/>	21.08.2023 - 25.08.2023
        </Typography>
        <Typography sx={style.description}>
          Cena za turnus 5-dniowy<br/> <b>770,00 zł</b>.
        </Typography>
        <Typography sx={style.description}>
          <b>Cena zawiera</b>
        </Typography>
        <Typography sx={style.description}>
          • opiekę wykwalifikowanej kadry nad dzieckiem w godzinach 8:00 – 16:00<br/>
          • atrakcje zgodnie z programem pólkolonii<br/>
          • wyżywienie 2 posiłki dziennie (II śniadanie, dwudaniowy obiad)<br/>
          • ubezpieczenie NNW<br/>
        </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
        <img src={image7} alt="image7" style={style.img} />
        <img src={wych} alt="image7" style={style.img} />
      </Grid>
      <Grid item md={12} xs={12}>
        <Typography sx={style.descriptionCenter}>
          <b>Atrakcje dodatkowe</b>
        </Typography>
      </Grid>
     <Grid item md={12} sx={{ padding: '0 !important' }}>
       <Grid container spacing={4} sx={{ padding: '0 !important' }}>
         <Grid item md={4} xs={12}>
           <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', height: '100%' }}>
             <img src={koszulka} alt="image13" style={style.img} />
             <Typography sx={style.descriptionCenter}>
               <b>Koszulka strażacka</b><br/>
               Unikatowa koszulka strażacka
               Młodzieżowej Drużyny Strażackiej !<br/>
               <b>Cena: 69.00 zł</b><br/>
             </Typography>
           </Box>
         </Grid>
         <Grid item md={4} xs={12}>
           <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', height: '100%' }}>
             <img src={fotorelacja} alt="image13" style={style.img} />
             <Typography sx={style.descriptionCenter}>
               <b>Fotorelacja z każdego dnia półkolonii</b><br/>
               Wspaniała pamiątka wakacyjnej przygody w
               straży pożarnej. Dostęp do kilkuset zdjęć
               z turnusu, wykonanych przez
               profesjonalnych fotografów.<br/>
               <b>Cena: 50.00 zł</b><br/>
             </Typography>
           </Box>
         </Grid>
       </Grid>
     </Grid>
    </Grid>
  )
}

export default OfferPage
