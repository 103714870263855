import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import { Grid, Typography } from '@mui/material'
import { createOrUpdateReservations } from '../../firebase'
import { useSnackbar } from 'notistack'
import image from '../../assets/images/12.jpeg'
import karta from '../../assets/files/kartaKwalifikacyjna.pdf'
import regulamin from '../../assets/files/regulaminOrganizacyjny.pdf'

const SaveKidsPage = () => {
  const { enqueueSnackbar } = useSnackbar()
  const style = {
    title: {
      fontSize: '34px',
      fontWeight: '900',
      textTransform: 'uppercase',
      marginBottom: '10px',
    },
    description: {
      fontSize: '20px',
      fontWeight: '300',
      marginBottom: '20px',
      textAlign: 'justify',
    },
    contacts: {
      fontSize: '28px',
      fontWeight: '300',
      marginBottom: '20px',
      textAlign: 'justify',
    },
    img: {
      width: '100%',
    }
  }
  const formData = {
    elements: [
      {
        name: 'turnus',
        type: 'select',
        label: 'Wybierz turnus',
        items: [{ name: '26.06.2023 - 30.06.2023', value: '26.06.2023 - 30.06.2023' }, { name: '03.07.2023 - 07.07.2023', value: '03.07.2023 - 07.07.2023' }, { name: '21.08.2023 - 25.08.2023', value: '21.08.2023 - 25.08.2023' }],
        validateType: 'string',
      },
      {
        name: 'name',
        type: 'text',
        label: 'Imię i nazwisko dziecka',
        validateType: 'string',
      },
      {
        name: 'date',
        type: 'date',
        label: 'Data urodzenia dziecka',
        value: '2023-08-26',
        validateType: 'string',
      },
      {
        name: 'parentName',
        type: 'text',
        label: 'Imię i nazwisko rodzica',
        validateType: 'string',
      },
      {
        name: 'parentPhone',
        type: 'text',
        label: 'Telefon rodzica',
        validateType: 'string',
      },
      {
        name: 'parentEmail',
        type: 'text',
        label: 'Email rodzica',
        validateType: 'string',
      },
      {
        name: 'adds',
        type: 'editor',
        label: 'Uwagi dotyczące dziecka',
        helperText: 'Np:. specjalna dieta, uczulenia, problemy zdrowotne',
        validateType: 'string',
      },
      {
        name: 'question',
        type: 'editor',
        label: 'Pytania do organizatora',
        validateType: 'string',
      },
      {
        name: 'regulation',
        type: 'checkbox',
        label: 'Akceptuje postawienia regulaminu półkolonii',
        validateType: 'string',
      },
      {
        name: 'policy',
        type: 'checkbox',
        label: 'Akceptuje warunki polityki prywatności. Administratorem Państwa danych osobowych jest firma OSP Mścice. Dane podane w formularzu będą przetwarzane i zapisywane, wykorzystywane tylko i wyłącznie w celu przesłania zapisów na półkolonie. Ponadto informujemy, że przetwarzamy Państwa dane zgodnie z prawem i w sposób bezpieczny oraz że przysługuje Państwu możliwość wycofania zgody na przetwarzanie danych. Więcej na stronie Polityka prywatności.',
        validateType: 'string',
      },
      {
        type: 'button',
        value: 'Zapisz dziecko',
      },
    ]
  }
  const handleData = (e) => {
    createOrUpdateReservations(e).then((res) => {
      if (res) {
        enqueueSnackbar('Udało się zapisać dziecko na półkolonie, o dalszych krokach będziecie informowani. Skontaktujemy się z wami.', { variant: 'success' })
      }
    })
  }
  return (
    <Grid container spacing={8}>
      <Grid item md={6} xs={12}>
        <Typography sx={style.title}>
          Formularz do zapisu na półkolonie
        </Typography>
        <Typography sx={style.description}>
          Karta kwalifikacyjna uczestnika półkolonii do pobrania <a href={karta} target="_blank" rel="noreferrer"><b>tutaj</b></a>
        </Typography>
        <Typography sx={style.description}>
          Regulamin półkolonii do pobrania <a href={regulamin} target="_blank" rel="noreferrer"><b>tutaj</b></a>
        </Typography>
        <FormGenerator data={formData} submit={(e) => handleData(e)} />
      </Grid>
      <Grid item md={6} xs={12}>
        <img src={image} alt="imagesLogo" style={{ width: '100%' }} />
      </Grid>
    </Grid>
  )
}

export default SaveKidsPage
