import { Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import image5 from '../../assets/images/5.png'
import image17 from '../../assets/images/17.png'
import Deklaracjamdp from '../../assets/files/Deklaracjamdp.pdf'

const TeamChildPage = () => {
  const style = {
    text: {
      marginBottom: '20px',
      textAlign: 'justify',
    },
    textHeading: {
      fontSize: '34px',
      fontWeight: '900',
      textTransform: 'uppercase',
      marginBottom: '10px',
    },
    textHeadingCenter: {
      fontSize: '34px',
      fontWeight: '900',
      textTransform: 'uppercase',
      marginBottom: '50px',
      textAlign: 'center',
    },
    textCenter: {
      textAlign: 'center',
      marginBottom: '10px',
    },
    box: {
      padding: '0px 0',
    },
    img: {
      width: '100%',
    },
  }
  return (
    <div>
      <Box sx={style.box}>
        <Grid container spacing={4}>
          <Grid item md={12}>
            <Box sx={{ marginBottom: '50px', width: '100%' }}>
              <Grid container spacing={4}>
                <Grid item md={6} xs={12}>
                  <Typography variant="h4" sx={style.textHeading}><b>Młodzieżowa drużyna pożarnicza</b></Typography>
                  <Typography sx={style.text}>
                    Młodzieżowe drużyny pożarnicze są wyjątkowymi organizacjami, które gromadzą młodych ludzi w wieku od 10 do 18 lat z pasją i zainteresowaniem w dziedzinie pożarnictwa, ratownictwa oraz bezpieczeństwa. Pod opieką doświadczonych strażaków, te grupy działają w celu edukacji, szkolenia i rozwijania umiejętności młodych adeptów w dziedzinie ochrony przeciwpożarowej.
                    Głównym celem młodzieżowych drużyn pożarniczych jest kształtowanie odpowiedzialności społecznej, patriotyzmu oraz propagowanie wartości obywatelskich wśród młodzieży. Poprzez uczestnictwo w akcjach społecznych, zawodach, szkoleniach i działalnościach charytatywnych, młodzi strażacy rozwijają umiejętności interpersonalne, zdobywają wiedzę z zakresu ratownictwa i pożarnictwa, oraz uczą się, jak działać w sytuacjach awaryjnych.
                    Młodzieżowe drużyny pożarnicze organizują regularne szkolenia teoretyczne i praktyczne, w tym ćwiczenia gaśnicze, naukę udzielania pierwszej pomocy, techniki wspinaczki, oraz obsługę sprzętu pożarniczego. Symulacje realistycznych scenariuszy pozwalają młodym strażakom na wykorzystanie zdobytej wiedzy w praktyce i doskonalenie swoich umiejętności. Wielu członków młodzieżowych drużyn pożarniczych wyraża chęć podjęcia przyszłej kariery zawodowej jako strażacy lub pracownicy służb ratowniczych, co czyni te szkolenia szczególnie cenne dla realizacji ich aspiracji.
                    Młodzieżowi strażacy uczestniczą w akcjach informacyjnych i edukacyjnych, gdzie propagują zasady bezpieczeństwa w społeczności lokalnej. Ponadto, młodzieżowe drużyny pożarnicze są aktywne w działaniach prewencyjnych, organizując programy edukacyjne w szkołach i przedszkolach. Działalność tych drużyn ma na celu zwiększenie świadomości wśród dzieci i młodzieży na temat zagrożeń pożarowych oraz naukę podstawowych zasad bezpieczeństwa.
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <img src={image17} alt="image17" style={style.img} />
                </Grid>
                <Grid item md={6} xs={12}>
                  <img src={image5} alt="image5" style={style.img}/>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography sx={style.text}>
                    Udział w młodzieżowych drużynach pożarniczych rozwija umiejętności pracy zespołowej, komunikacyjne i przywództwa. Młodzi strażacy uczą się odpowiedzialności za swoje czyny oraz za bezpieczeństwo innych osób, co jest kluczowe w działaniach ratowniczych. Integracja i współpraca w zespole sprawiają, że młodzi strażacy zyskują nie tylko doświadczenie w dziedzinie pożarnictwa, ale również kształtują wartości takie jak solidarność i wzajemna pomoc.
                    Młodzieżowe drużyny pożarnicze odgrywają także ważną rolę w rozwoju społeczeństwa świadomego zagrożeń pożarowych. Organizują różnorodne akcje charytatywne, wspierają lokalne inicjatywy i promują bezpieczeństwo w społecznościach, w których działają. Często uczestniczą w lokalnych imprezach i wydarzeniach, gdzie prezentują swoją działalność i angażują się w życie społeczności.
                    Młodzieżowi strażacy mają okazję do rywalizacji z rówieśnikami z innych drużyn w zawodach i konkursach z zakresu ratownictwa i bezpieczeństwa. Te wydarzenia nie tylko rozwijają umiejętności techniczne, ale także integrują młodzież w duchu sportowej rywalizacji.
                    Współpraca z innymi organizacjami młodzieżowymi, służbami ratowniczymi i strażami pożarnymi pozwala na wymianę doświadczeń i wspólne działania. To umożliwia młodym strażakom poszerzenie horyzontów i zdobycie nowych umiejętności.
                    Młodzieżowe drużyny pożarnicze mają swoje statuty i regulaminy, które określają zasady działania, przynależności i obowiązki członków drużyny. Dzięki temu, uczestnictwo w tych organizacjach pozwala na rozwijanie umiejętności organizacyjnych i samodyscypliny.
                    Udział w młodzieżowych drużynach pożarniczych to nie tylko szansa na zdobycie praktycznej wiedzy i umiejętności, ale również na rozwinięcie pasji oraz na zapewnienie wsparcia i aktywności wśród młodych ludzi, którzy chcą działać na rzecz bezpieczeństwa społeczności. Młodzieżowe drużyny pożarnicze stanowią doskonałą platformę dla młodych adeptów, umożliwiając im rozwijanie się, zdobywanie doświadczenia oraz kształtowanie wartości społecznych, co wpływa pozytywnie na ich przyszłe życie osobiste i zawodowe.
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Typography sx={style.text}>
                    Z wielkim entuzjazmem pragniemy Cię zaprosić do naszej młodzieżowej drużyny pożarniczej. Jesteśmy zgrupowaniem młodych ludzi, którzy chcą oddać swoje umiejętności i czas na rzecz bezpieczeństwa społeczności. W naszym zespole spotkasz osoby o podobnych zainteresowaniach, gotowe do nauki i wspólnego działania w sytuacjach kryzysowych. Członkostwo w naszej organizacji pozwoli Ci na zdobycie niezastąpionych umiejętności, takich jak pierwsza pomoc, ratownictwo, czy udzielanie wsparcia w sytuacjach awaryjnych. Dołączając do nas, staniesz się częścią szanowanej i oddanej społeczności pożarniczej, która dba o bezpieczeństwo i dobro wspólne. Jeżeli jesteś zainteresowany wstąpieniem w szeregi naszej organizacji a masz ptytania skontaktuj się z nami telefonicznie pod numerem telefonu <b>668 822 926</b>, <b>666 242 421</b> lub drogą elektoniczną <b>mdp@ospmscice.pl</b>
                  </Typography>
                  </Grid>
                <Grid item md={12} xs={12}>
                  Deklaracja Członkowska Młodzieżowej Drużyny Pożarniczej do pobrania <a href={Deklaracjamdp} target="_blank" rel="noreferrer"><b>tutaj</b></a>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default TeamChildPage
