import { useEffect, useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import Button from '@mui/material/Button'
import bannerImage from '../../../assets/images/slider.jpg'
import image5 from '../../../assets/images/5.png'
import { useHistory } from 'react-router'
import { Grid } from '@mui/material'

const Slider = () => {
  const history = useHistory()
  const [items, setItems] = useState([])
  const Item = (props) => {
    const el = props.item
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
          minHeight: 'calc(100vh - 120px - 130px)',
          position: 'relative',
          zIndex: 1
        }}
      >
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <Typography
              sx={{
                fontSize: BreakpointsHelper.isMobile() ? '40px' : '60px',
                maxWidth: '600px',
                lineHeight: BreakpointsHelper.isMobile() ? '40px' : '60px',
                marginTop: '10px',
                marginBottom: '30px',
                fontWeight: '900',
                zIndex: 1
              }}
            >
              {el?.textCenter}
            </Typography>
            <Typography
              sx={{
                fontSize: '24px',
                marginBottom: '30px',
                zIndex: 1,
                maxWidth: BreakpointsHelper.isMobile() ? '80%' : 'none'
              }}
            >
              {el?.textBottom}
            </Typography>
            <Button
              className="button"
              variant="contained"
              id="about"
              aria-label="Go to abous us"
              onClick={() => history.push(el?.buttonLink)}
              sx={{ zIndex: 1, minWidth: '200px', padding: '10px' }}
            >
              {el?.textButton}
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <img
              src={el?.image}
              style={{
                width: '100%',
                filter: BreakpointsHelper.isMobile() && 'opacity(0.2)'
              }}
              alt="right image"
            />
          </Grid>
        </Grid>
      </Box>
    )
  }
  useEffect(() => {
    const allItems = []
    allItems.push({
      textCenter: 'Podstawa to ochrona waszych bliskich',
      textBottom: 'Ochotnicza Straż Pożarna w Mścicach czuwa nad bezpieczeństwem Waszych rodzin, ale również posiada szeroką ofertę usług spoza ochrony ratowniczo - gaśniczej.',
      textButton: 'Sprawdź naszą ofertę',
      buttonLink: '/offer',
      image: bannerImage
    })
    allItems.push({
      textCenter: 'Nabór do Młodzieżowej Drużyny Pożarniczej',
      textBottom: 'Z wielkim entuzjazmem pragniemy Cię zaprosić do naszej młodzieżowej drużyny pożarniczej. Jesteśmy zgrupowaniem młodych ludzi, którzy chcą oddać swoje umiejętności i czas na rzecz bezpieczeństwa społeczności.',
      textButton: 'Sprawdź co robimy',
      buttonLink: '/ourChildTeam',
      image: image5
    })
    setItems(allItems)
  }, [])
  return (
    <Carousel sx={{ width: 'calc(100% - 100px)', padding: '50px' }}>
      {
        items?.length > 0 && items?.map((item, i) => <Item key={i} item={item}/>)
      }
    </Carousel>
  )
}

export default Slider
